const config = {
  ccpp: {
    securePayUrl: 'https://demo2.2c2p.com/2C2PFrontEnd/SecurePayment/PaymentAuth.aspx',
    my2c2pUrl: 'https://demo2.2c2p.com/2C2PFrontEnd/SecurePayment/api/my2c2p.1.6.9.min.js',
  },
  npay: {
    webUrl: 'https://p8-uat.sfinpay.com',
    apiUrl: 'https://p8-api-uat.sfinpay.com',
  },
  kbank: {
    kinlinepaymentUrl: 'https://dev-kpaymentgateway.kasikornbank.com/ui/v2/kinlinepayment.min.js',
  },
  ktc: {
    payment_url: 'https://testpaygate.ktc.co.th/scs/eng/merchandize/payment/payForm.jsp',
    merchantId: '886000118',
    currCode_th: '764',
    currCode_us: '840',
    payType: 'N',
    lang: 'T',
    txType: 'Retail',
  },
  bbl: {
    postBackUrl: 'https://nexterpay.nexterdigitals-dev.com/api/payments/postback/bbl/',
    chargeActionUrl: 'https://psipay.bangkokbank.com/b2c/eng/payment/payerVerify.jsp',
    apiId: '5652',
  },
  features: {
    mainCredit: true,
    scgWallet: true,
    ktcScg: true,
    multiGateway: false,
    qr: false,
    line: true,
    changFamilyWallet: true,
  },
  auth: {
    pkey: '2JnLEFlaZX5mUy7rU1JFn6zh75oo5kF0',
  },
  time_calibate: {
    UTC: 7,
  },
};

module.exports = config;

