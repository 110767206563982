import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { config } from '../../config';
import iCreditCard from '../../assets/img/icons/creditcard-24px.svg';
import iMobileBanking from '../../assets/img/icons/mobilebanking-24px.svg';
import iQrBox from '../../assets/img/icons/qr_code-24px.svg';
import iCounterService from '../../assets/img/icons/counter_service-24px.svg';
import iSCGCashCard from '../../assets/img/icons/scg_cash_card-24px.svg';
import iInstallment from '../../assets/img/icons/installment-24px.svg';
import iChangFamilyWallet from '../../assets/img/icons/chang_family_wallet-24px.svg';
// import iLinePay from '../../assets/img/icons/linepay-24px.svg';
import iLinePay from '../../assets/img/icons/linepay.png';
import iPromptpay from '../../assets/img/icons/promptpay-full-small.png';
import iGrabPay from '../../assets/img/icons/grabpay-full-small.png';
import iCBDC from '../../assets/img/icons/cbdc-pilot.png';

class PaymentMethod extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [
        {
          value: 'credit_card',
          text: 'Credit Card',
          // icon: 'far fa-fw fa-credit-card',
          icon: iCreditCard,
          show: config.features.mainCredit,
        },
        {
          value: 'mobile_banking_opn',
          text: 'Mobile Banking (App Switch)',
          icon: iMobileBanking,
          height: 24,
          show: true,
        },
        {
          value: 'grabpay',
          text: 'GrabPay (Credit Card Only)',
          icon: iGrabPay,
          height: 24,
          show: true,
        },
        {
          value: 'promptpay',
          text: 'Thai QR Payment',
          // icon: 'far fa-fw fa-credit-card',
          icon: iPromptpay,
          height: 24,
          show: true,
        },
        {
          value: 'cbdc',
          text: 'CBDC Payment',
          // icon: 'far fa-fw fa-credit-card',
          icon: iCBDC,
          height: 24,
          show: true,
        },
        {
          value: 'bill_payment_mobile',
          text: 'Mobile Banking',
          // icon: 'fas fa-fw fa-mobile-alt',
          icon: iMobileBanking,
          show: true,
        },
        {
          value: 'qr_box',
          text: 'QR / Bill Payment (BBL)',
          // icon: 'fas fa-fw fa-qrcode',
          icon: iQrBox,
          show: config.features.qr,
        },
        {
          value: 'bill_payment',
          text: 'Counter Service',
          // icon: 'fa fa-barcode',
          icon: iCounterService,
          show: true,
        },
        {
          value: 'scg_wallet',
          text: ' SCG Cash Card / SCG Wallet',
          // icon: 'fas fa-fw fa-wallet',
          icon: iSCGCashCard,
          show: config.features.scgWallet,
        },
        {
          value: 'chang_family_wallet',
          text: ' Chang Family Wallet',
          // icon: 'fas fa-fw fa-wallet',
          icon: iChangFamilyWallet,
          show: config.features.changFamilyWallet,
        },
        {
          value: 'installment',
          text: 'Installment (แบ่งชำระ)',
          // icon: 'fas fa-fw fa-calendar-alt',
          icon: iInstallment,
          show: false,
        },
        {
          value: 'line',
          text: ' Line Pay',
          // icon: 'fab fa-line',
          icon: iLinePay,
          width: 66,
          show: config.features.line,
        },
        // {
        //   value: 'credit_card_multi',
        //   text: 'บัตรเครดิต / Credit Card (Dev)',
        //   icon: 'far fa-fw fa-credit-card',
        //   show: config.features.multiGateway,
        // },
        // {
        //   value: 'ktc_scg',
        //   text: 'บัตรช่าง / KTC SCG',
        //   icon: 'fas fa-fw fa-credit-card',
        //   show: config.features.ktcScg,
        // },
      ],
    };
  }

  componentDidMount() {
    let options = this.filterOptions(this.state.options);
    let activeOptions = options.filter(option => option.show);
    if (activeOptions.length === 1) {
      this.changePaymentMethod(activeOptions[0].value);
    }
  }

  changePaymentMethod(method) {
    this.props.payment.changePaymentMethod(method);
    if (this.props.changeStateStep) {
      this.props.changeStateStep(1, 'done');
      this.props.changeStateStep(2, 'active');
    }
  }

  filterOptions(allOptions = []) {
    let options = [...allOptions];
    let { payment } = this.props.payment.getData();
    let inTime = false;

    const today = new Date();
    if (payment.channel) {
      const startDate = new Date(moment(payment.channel.installment.start_date).subtract(config.time_calibate.UTC, 'hours').toDate());
      startDate.setHours(0, 0, 0);
      const endDate = new Date(moment(payment.channel.installment.end_date).subtract(config.time_calibate.UTC, 'hours').toDate());
      endDate.setHours(23, 59, 59);
      if (today >= startDate && today <= endDate) {
        inTime = true;
      }
    }

    if (payment.channel && payment.channel.installment.cards.length && payment.total >= payment.channel.installment.minimum_amount && inTime) {
      let option = options.find(item => item.value === 'installment');
      option.show = true;
    }

    if (payment.seller?.type === 'channel') {
      const paymentOptions = payment?.channel?.channel_payment_methods;
      let option = options.find(item => item.value === 'bill_payment_mobile');
      option.show = !paymentOptions.includes('qr_box');
    }

    if (payment.channel && payment.seller && payment.channel.id === payment.seller.channel_id && payment.seller.type === 'channel') {
      let option = options.find(item => item.value === 'qr_box');
      option.show = true;
    } else {
      let option = options.find(item => item.value === 'qr_box');
      option.show = false;
    }

    // show payment method by channel
    // eslint-disable-next-line camelcase
    const paymentOptions = payment?.channel?.channel_payment_methods;
    if (paymentOptions) {
      let arrOptions = paymentOptions ?? [];
      options = options.map(option => {
        let show = !!arrOptions.find(po => po === option.value);
        if (option.value === 'bill_payment_mobile' && show) show = option.show;
        if (option.value === 'installment' && show) show = option.show;
        if (option.value === 'qr_box' && show) show = option.show;
        if (option.value === 'chang_family_wallet' && !payment.chang_family_payment_info) show = false;
        return { ...option, show };
      });
    }

    return options;
  }

  render() {
    let { payment } = this.props.payment.getData();
    let options = this.filterOptions(this.state.options);

    // let active = { color: '#fff', background: '#e92a2a' };
    // let active = {
    //   color: '#000',
    //   border: '2px solid #e92a2a',
    // };
    // let inactive = {
    //   color: '#000',
    //   border: '2px solid #fff',
    // };
    // const items = options.filter(item => item.show).map(item => {
    //   return (
    //     <NavItem key={item.value}>
    //       <NavLink href="#"
    //         style={payment.method === item.value ? active : inactive}
    //         onClick={this.changePaymentMethod.bind(this, item.value)}>
    //         <i className={item.icon} /> {item.text}
    //       </NavLink>
    //     </NavItem>
    //   );
    // });

    return (
      <>
        {options
          .filter(item => item.show)
          .map((opt, i) => {
            return (
              <div key={i} className="pt-1">
                <button className={`btn-payment ${payment.method === opt.value ? 'active' : ''}`} onClick={this.changePaymentMethod.bind(this, opt.value)}>
                  <div className="row">
                    <div className="col-3 text-center">
                      <img className="rounded" src={opt.icon} {...(opt.height && { height: opt.height })} {...(opt.width && { width: opt.width })} />
                    </div>
                    <div className="col-9 text-left text-black font-medium">
                      <span>{opt.text}</span>
                    </div>
                  </div>
                </button>
              </div>
            );
          })}
      </>
      // <Nav pills className="flex-column">
      //   {items}
      // </Nav>
    );
  }
}

export default inject('payment', 'channel')(observer(PaymentMethod));
